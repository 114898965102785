const theme = {
  colors: {
    primary: {
      50: '#9af2aa',
      100: '#68eb7f',
      200: '#4fe86a',
      300: '#35e555',
      400: '#1ce13f',
      500: '#03de2a',
      600: '#03c826',
      700: '#02b222',
      800: '#029b1d',
      900: '#028519',
    },
    secondary: {
      50: '#9af2aa',
      100: '#68eb7f',
      200: '#4fe86a',
      300: '#35e555',
      400: '#1ce13f',
      500: '#03de2a',
      600: '#03c826',
      700: '#02b222',
      800: '#029b1d',
      900: '#028519',
    },
    dark: {
      50: '#c9c9c9',
      100: '#a8a8a8',
      200: '#888888',
      300: '#676767',
      400: '#474747',
      500: '#262626',
      600: '#202020',
      700: '#1b1b1b',
      800: '#151515',
      900: '#000000',
    },
    bright: {
      50: '#f8f8f8',
      100: '#efefef',
      200: '#e7e7e7',
      300: '#dedede',
      400: '#d6d6d6',
      500: '#cdcdcd',
      600: '#aaaaaa',
      700: '#878787',
      800: '#646464',
      900: '#424242',
    },
  },
};

export default theme;
